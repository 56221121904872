// Страницы для приложения Electron
export enum PageNames {
    CheckDevices = 'CheckDevices',
    LoginInApp = 'LoginInApp',
    LoginInAppSuccess = 'LoginInAppSuccess',
    LoginAsEmployee = 'LoginAsEmployee',
    Presentation = 'Presentation',
    VolumeSettings = 'VolumeSettings',
    Employee = 'Employee',
    EmployeeExams = 'EmployeeExams',
    EmployeeCheckDevices = 'EmployeeCheckDevices',
    EmployeeMarkQuestions = 'EmployeeMarkQuestions',
    EmployeePassExam = 'EmployeePassExam',
    EmployeeExamsToAssessment = 'EmployeeExamsToAssessment',
    EmployeePresentation = 'EmployeePresentation',
}

export enum DeviceError {
    Screen = 1,
    Camera = 2,
    Microphone = 3
}
